import { MouseEvent } from "react";
import { BiLoaderAlt } from "react-icons/bi";

const Button = ({
	variant = "fill",
	children,
	onClick,
	className = "",
	loading = false,
	disabled = false,
	type = "button",
	size = "lg",
	fullWidth = false,
}) => {
	return (
		<button
			className={`btn btn-primary mb-0 ${
				fullWidth ? "w-full" : "w-max"
			} cursor-pointer rounded-[10px] px-5 py-3 flex items-center justify-center ${className}`}
			onClick={(e) => {
				if (disabled) e.preventDefault();
				else if (!loading) onClick && onClick();
			}}
			// type={type ?? "button"}
		>
			{loading ? <BiLoaderAlt className="loader" /> : children}
		</button>
	);
};

export default Button;
