import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputComponent from "../../components/InputComponent";
import { toast } from "react-toastify";
import { authContext } from "../../hooks/useAuth";
import { userLogin } from "../../requests/auth";
import Button from "../../components/Button";

export default function LoginPage() {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const { isLoggedIn, setIsLoggedIn, setUserObj } = useContext(authContext);

	const submitFunc = async (e) => {
		e.preventDefault();
		// log values
		const { email, password } = e.target;
		console.log({
			email: email.value,
			password: password.value,
		});

		try {
			setLoading(true);
			const res = await userLogin({
				email: email.value,
				password: password.value,
			});
			console.log("res", res);

			if (res?.status === 200) {
				toast.success("Login successful");
				setIsLoggedIn(true);
				setUserObj(res.data?.user);
				navigate("/quiz");
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="col-12 col-lg-6 m-auto">
			<div className="row my-5">
				<div className="col-sm-10 col-xl-8 m-auto">
					{/* Title */}
					<span className="mb-0 fs-1">👋</span>
					<h1 className="fs-2">Login into Eduport!</h1>
					<p className="lead mb-4">
						Nice to see you! Please log in with your account.
					</p>

					{/* Form START */}
					<form onSubmit={submitFunc}>
						<InputComponent
							label="Email address *"
							type="email"
							placeholder="Enter your email"
							name="email"
						/>
						<InputComponent
							label="Password"
							type="password"
							placeholder="Enter password"
							// labelSide={
							// 	<Link to="/auth/forgot-password" className="text-base">
							// 		Forgot password?
							// 	</Link>
							// }
							name="password"
						/>

						{/* Check box */}
						<div className="mb-4 d-flex justify-content-between mb-4">
							<div className="form-check">
								<input
									type="checkbox"
									className="form-check-input"
									id="exampleCheck1"
								/>
								<label className="form-check-label" for="exampleCheck1">
									Remember me
								</label>
							</div>
							<div className="text-primary-hover">
								<a href="forgot-password.html" className="text-secondary">
									<u>Forgot password?</u>
								</a>
							</div>
						</div>
						{/* Button */}
						<div className="align-items-center mt-0">
							<div className="d-grid">
								<Button
									onClick={() => {}}
									fullWidth={true}
									loading={loading}
									type="submit"
								>
									Log in
								</Button>
							</div>
						</div>
					</form>
					{/* Form END */}

					{/* Sign up link */}
					<div className="mt-4 text-center">
						<span>
							Don't have an account? <Link to="/register">Signup here</Link>
						</span>
					</div>
				</div>
			</div>{" "}
			{/* Row END */}
		</div>
	);
}
