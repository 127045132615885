export const getToken = () => {
	return localStorage.getItem("HEAR_TKN");
};

export const setAuthToken = (token) => {
	localStorage.setItem("HEAR_TKN", token);
};

export const deleteToken = () => {
	return localStorage.removeItem("HEAR_TKN");
};
