import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputComponent from "../../components/InputComponent";
import Button from "../../components/Button";
import { registerUser } from "../../requests/auth";
import { toast } from "react-toastify";
import { authContext } from "../../hooks/useAuth";

export default function RegisterPage() {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const { isLoggedIn, setIsLoggedIn, setUserObj } = useContext(authContext);

	const submitFunc = async (e) => {
		e.preventDefault();
		// log values
		const { first_name, last_name, email, password, password_confirmation } =
			e.target;
		console.log({
			first_name: first_name.value,
			last_name: last_name.value,
			email: email.value,
			password: password.value,
			password_confirmation: password_confirmation.value,
		});

		// check if passwords match
		if (password.value !== password_confirmation.value) {
			toast.error("Passwords don't match");
			return;
		}

		try {
			setLoading(true);
			const res = await registerUser({
				first_name: first_name.value,
				last_name: last_name.value,
				email: email.value,
				password: password.value,
				password_confirmation: password_confirmation.value,
			});
			console.log("res", res);

			if (res?.status === 200) {
				toast.success("Account created successfully");
				setIsLoggedIn(true);
				setUserObj(res.data?.user);
				navigate("/quiz");
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="col-12 col-lg-6 m-auto">
			<div className="row my-5">
				<div className="col-sm-10 col-xl-8 m-auto">
					{/* Title */}
					<img
						src="assets/images/element/03.svg"
						className="h-40px mb-2"
						alt=""
					/>
					<h2>Sign up for your account!</h2>
					<p className="lead mb-4">
						Nice to see you! Please Sign up with your account.
					</p>

					{/* Form START */}
					<form onSubmit={submitFunc}>
						<InputComponent
							label="First Name"
							type="text"
							placeholder="Enter first name"
							name="first_name"
							required={true}
						/>
						<InputComponent
							label="Last Name"
							type="text"
							placeholder="Enter last name"
							name="last_name"
							required={true}
						/>
						<InputComponent
							label="Email"
							type="email"
							name="email"
							placeholder="Enter email"
						/>
						<InputComponent
							label="Password"
							type="password"
							placeholder="Enter password"
							name="password"
							required={true}
						/>

						<InputComponent
							label="Confirm Password *"
							type="password"
							placeholder="Confirm your password"
							name="password_confirmation"
							required={true}
						/>

						{/* Check box */}
						<div className="mb-4">
							<div className="form-check">
								<input
									type="checkbox"
									className="form-check-input"
									id="checkbox-1"
								/>
								<label className="form-check-label" for="checkbox-1">
									By signing up, you agree to the
									<a href="#"> terms of service</a>
								</label>
							</div>
						</div>
						{/* Button */}
						<div className="align-items-center mt-0">
							<div className="d-grid">
								<Button onClick={() => {}} fullWidth={true} loading={loading} type="submit">
									Create my account
								</Button>
							</div>
						</div>
					</form>
					{/* Form END */}

					{/* Sign up link */}
					<div className="mt-4 text-center">
						<span>
							Already have an account?
							<Link to="/login"> Sign in here</Link>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
