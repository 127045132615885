import { useEffect, useState } from "react";
import AccordionComponent from "../components/AccordionComponent";
import QuizOptions from "../components/QuizOptions";
import { fetchQuizQuestions, submitQuizAnswers } from "../requests/quiz";
import { BiLoaderAlt } from "react-icons/bi";
import Button from "../components/Button";
import { toast } from "react-toastify";

const QuizPage = () => {
	const questions = [
		{
			title: "How do you protect your business against cyber-crime?",
			options: [
				"We have cybersecurity insurance coverage",
				"Our dedicated staff will protect us",
				"We give regular training for best practices",
				"Third-party vendor protection",
			],
		},
		{
			title: "How do you protect your business against cyber-crime?",
			options: [
				"We have cybersecurity insurance coverage",
				"Our dedicated staff will protect us",
				"We give regular training for best practices",
				"Third-party vendor protection",
			],
		},
		{
			title: "How do you protect your business against cyber-crime?",
			options: [
				"We have cybersecurity insurance coverage",
				"Our dedicated staff will protect us",
				"We give regular training for best practices",
				"Third-party vendor protection",
			],
		},
		{
			title: "How do you protect your business against cyber-crime?",
			options: [
				"We have cybersecurity insurance coverage",
				"Our dedicated staff will protect us",
				"We give regular training for best practices",
				"Third-party vendor protection",
			],
		},
		{
			title: "How do you protect your business against cyber-crime?",
			options: [
				"We have cybersecurity insurance coverage",
				"Our dedicated staff will protect us",
				"We give regular training for best practices",
				"Third-party vendor protection",
			],
		},
		{
			title: "How do you protect your business against cyber-crime?",
			options: [
				"We have cybersecurity insurance coverage",
				"Our dedicated staff will protect us",
				"We give regular training for best practices",
				"Third-party vendor protection",
			],
		},
	];

	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [questionsList, setQuestionsList] = useState([]);
	const [solutionList, setSolutionList] = useState([]);

	useEffect(() => {
		const fetchFunc = async () => {
			setLoading(true);
			try {
				const res = await fetchQuizQuestions();
				console.log(res);

				setQuestionsList(res?.data);
				setSolutionList(
					res?.data?.map((item) => ({
						question_id: item.id,
						answer_id: 0,
					}))
				);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		fetchFunc();
	}, []);

	const submitFunc = async () => {
		setSubmitLoading(true);
		try {
			const res = await submitQuizAnswers({ answers: solutionList });
			console.log(res);
		} catch (error) {
			console.log(error);
		} finally {
			setSubmitLoading(false);
		}
	};
	console.log("solutionList", solutionList);

	return loading ? (
		<div className=" w-100 d-flex flex-row align-items-center justify-content-center py-5">
			<BiLoaderAlt className="loader" />
		</div>
	) : (
		<div>
			{/* Card START  */}
			<div className="card border bg-transparent rounded-3">
				{/* Card header START  */}
				<div className="card-header bg-transparent border-bottom px-3">
					<div className="row g-4 align-items-center">
						<div className="col-md-2">
							<img
								src="assets/images/courses/4by3/01.jpg"
								className="rounded-2"
								alt="Card image"
							/>
						</div>
						<div className="col-md-10">
							{/* Title  */}
							<h3 className="card-title mb-0">
								<a href="#">How well do you know God?</a>
							</h3>
						</div>
					</div>
				</div>
				{/* Card header END  */}

				{/* Card body START  */}
				<div className="card-body p-4">
					{/* Accordion START  */}
					<AccordionComponent
						dataList={questionsList.map((questionObj, questionIndex) => ({
							title: questionObj.question,
							body: (
								<QuizOptions
									options={questionObj.choices}
									questionIndex={questionIndex}
									updateSelection={(answer_id) => {
										console.log("updateSelection");
										setSolutionList([
											...solutionList.slice(0, questionIndex),
											{
												question_id: questionObj.id,
												answer_id: answer_id,
											},
											...solutionList.slice(questionIndex + 1),
										]);
									}}
								/>
							),
						}))}
					/>
					{/* Accordion END  */}
				</div>
				{/* Card body START  */}
				<div className="d-flex align-items-center justify-content-center my-5 ">
					<Button
						onClick={() => {
							submitFunc();
						}}
						fullWidth={true}
						loading={submitLoading}
						type="button"
					>
						Submit Answers
					</Button>
				</div>
			</div>
			{/* Card END  */}
		</div>
	);
};

export default QuizPage;
