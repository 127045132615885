const InputComponent = ({
	label,
	type,
	placeholder,
	name,
	required = false,
}) => {
	return (
		<label className="mb-4 w-100">
			<p className="form-label">{label}</p>
			<div className="input-group input-group-lg">
				<span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
					<i className="fas fa-lock"></i>
				</span>
				<input
					type={type}
					className="form-control border-0 bg-light rounded-end ps-1"
					placeholder={placeholder}
					name={name}
					required={required}
				/>
			</div>
		</label>
	);
};

export default InputComponent;
