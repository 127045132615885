import { useState } from "react";

const QuizOptions = ({ options = [], questionIndex, updateSelection }) => {
	const letters = ["A", "B", "C", "D", "E", "F"];

	const [selectedOption, setSelectedOption] = useState(null);

	return (
		<>
			<div>
				{/* Answer options  */}
				{options.map((option, ind) => (
					<p
						className={`py-3 px-2 flex ${
							selectedOption === ind ? "bg-primary text-white fw-bolder" : ""
						} `}
						onClick={() => {
							setSelectedOption(ind);
							updateSelection(option.id);
						}}
						style={{
							cursor: "pointer",
						}}
						key={ind}
					>
						<b className="text-dark me-3">{letters[ind]}</b>
						<span>{option.answer}</span>
					</p>
				))}
				{/* <p>Answer: A</p> */}
				{/* Button  */}
				{/* <a
                    href="#"
                    className="btn btn-sm btn-success-soft mb-0"
                    data-bs-toggle="modal"
                    data-bs-target="#editQuestion"
                >
                    Edit
                </a>
                <button className="btn btn-danger-soft btn-sm mb-0">
                    Delete
                </button> */}
			</div>
		</>
	);
};

export default QuizOptions;
