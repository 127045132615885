const AccordionComponent = ({ dataList }) => {
    return (
        <div
            className="accordion accordion-icon accordion-bg-light"
            id="accordionExample"
        >
            {dataList.map((accordionItem, ind) => {
                return (
                    <div className="accordion-item mb-3" key={ind}>
                        <h6 className="accordion-header" id="headingOne">
                            <button
                                className="accordion-button rounded collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                <span className="text-secondary fw-bold me-3">
                                    {ind + 1}
                                </span>
                                <span className="fw-bold">
                                    {accordionItem.title}
                                </span>
                            </button>
                        </h6>
                        <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                        >
                            <div className="accordion-body mt-3">
                                {accordionItem.body}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default AccordionComponent;
