import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { BASE_URL } from "./helpers/url";
import axios from "axios";
import { getConfig } from "./helpers/config";
import HomePage from "./pages/HomePage";
import QuizPage from "./pages/QuizPage";
import RegisterPage from "./pages/auth/RegisterPage";
import LoginPage from "./pages/auth/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import CoursesPage from "./pages/CoursesPage";
import Layout from "./layouts/Layout";
import { authContext } from "./hooks/useAuth";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Zoom } from "react-toastify";

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [userObj, setUserObj] = useState({});

	return (
		<authContext.Provider
			value={{ isLoggedIn, setIsLoggedIn, userObj, setUserObj }}
		>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<HomePage />} />
						<Route path="register" element={<RegisterPage />} />
						<Route path="login" element={<LoginPage />} />
						<Route path="profile" element={<ProfilePage />} />
						<Route path="quiz" element={<QuizPage />} />
						<Route path="courses" element={<CoursesPage />} />
					</Route>
				</Routes>
				<ToastContainer
					position="bottom-center"
					// autoClose={false}
					autoClose={5000}
					hideProgressBar={true}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover={true}
					transition={Zoom}
				/>
			</BrowserRouter>
		</authContext.Provider>
	);
}

export default App;
