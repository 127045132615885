import { request } from "../utils/axios";
import { setAuthToken } from "../config/helpers";
import { toast } from "react-toastify";

export const fetchQuizQuestions = async () => {
	try {
		const res = await request.get("test");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const submitQuizAnswers = async (values) => {
	try {
		const res = await request.post("test/submit", values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error("Error submitting answers");
		throw err;
	}
};
