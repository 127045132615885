import CourseCard from "../components/CourseCard";

const CoursesPage = () => {
    const courseList = [
        {
            title: "Sketch from A to Z: for app designer",
            desc: "Proposal indulged no do sociable he throwing settling.",
        },
        {
            title: "Graphic Design Masterclass",
            desc: "Rooms oh fully taken by worse do Points afraid but may end Rooms...",
        },
        {
            title: "Create a Design System in Figma",
            desc: "Rooms oh fully taken by worse do. Points afraid but may end afraid but may end afraid but...",
        },
        {
            title: "Deep Learning with React-Native",
            desc: "Far advanced settling say finished raillery. Offered chiefly farther",
        },
        {
            title: "Build Responsive Websites with HTML",
            desc: "Far advanced settling say finished raillery. Offered chiefly farther",
        },
        {
            title: "Build Websites with CSS",
            desc: "Far advanced settling say finished raillery. Offered chiefly farther",
        },
        {
            title: "Learn Invision",
            desc: "Arrived off she elderly beloved him Course regard to up he hardly.",
        },
    ];

    return (
        <main className="py-4 container">
            <div className="row mb-4">
                <div className="col-lg-8 mx-auto text-center">
                    <h2 className="fs-1">Most Popular Courses</h2>
                    <p className="mb-0">
                        Choose from hundreds of courses from specialist
                        organizations
                    </p>
                </div>
            </div>
            {/* Tabs content START */}
            <div className="tab-content" id="course-pills-tabContent">
                {/* Content START */}
                <div
                    className="tab-pane fade show active"
                    id="course-pills-tabs-1"
                    role="tabpanel"
                    aria-labelledby="course-pills-tab-1"
                >
                    <div className="row g-4">
                        {courseList.map((courseItem) => (
                            <CourseCard courseItem={courseItem} />
                        ))}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default CoursesPage;
