import "../../assets/css/style.css";
import { Link } from "react-router-dom";

const Header = () => {
	const headerList = [
		{ label: "Home", path: "/" },
		{ label: "Quiz", path: "/quiz" },
	];

	return (
		<header className="navbar-light navbar-sticky">
			{/* Logo Nav START */}
			<nav className="navbar navbar-expand-xl">
				<div className="container">
					{/* Logo START */}
					<a className="navbar-brand" href="index.html">
						<img
							className="light-mode-item navbar-brand-item"
							src="assets/images/logo.svg"
							alt="logo"
						/>
						<img
							className="dark-mode-item navbar-brand-item"
							src="assets/images/logo-light.svg"
							alt="logo"
						/>
					</a>
					{/* Logo END */}

					{/* Responsive navbar toggler */}
					<button
						className="navbar-toggler ms-auto"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarCollapse"
						aria-controls="navbarCollapse"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-animation">
							<span></span>
							<span></span>
							<span></span>
						</span>
					</button>

					{/* Main navbar START */}
					<div className="navbar-collapse w-100 collapse" id="navbarCollapse">
						{/* Nav Main menu START */}

						<ul className="navbar-nav navbar-nav-scroll mx-auto">
							{headerList.map((item, ind) => (
								<li className="nav-item" key={ind}>
									<Link className="nav-link" to={item.path}>
										{item.label}
									</Link>
								</li>
							))}
						</ul>

						{/* Nav Main menu END */}
					</div>
					{/* Main navbar END */}
				</div>
			</nav>
			{/* Logo Nav END */}
		</header>
	);
};

export default Header;
